import WormholeBridge, {
  DEFAULT_ROUTES,
  WormholeConnectConfig,
  WormholeConnectTheme,
} from "@wormhole-foundation/wormhole-connect";
import { useState } from "react";
// import WormholeBridge, { Theme, OPACITY, light, dark } from '@wormhole-foundation/wormhole-connect';
// import { Spin } from 'antd'
// import lightblue from '@mui/material/colors/lightBlue';
// import blue from '@mui/material/colors/blue';
import grey from "@mui/material/colors/grey";
// import green from '@mui/material/colors/green';
import red from "@mui/material/colors/red";
// import logo from './logo.svg';
import "./App.css";
// import { log } from 'console';
// import docsHover from "./images/default-img/docsHover.png"
// import twitterHover from "./images/default-img/twitterHover.png"
// import discordHover from "./images/default-img/discordHover.png"
import group from "./images/default-img/group.png";
// import groupHover from "./images/default-img/groupHover.png"
// import tgchannelHover from "./images/default-img/tgchannelHover.png"
import selectMenu from "./images/default-img/select-menu.png";
import portal from "./images/sui-img/Portal-Bridge.png";
import deepbook_img_hover from "./images/sui-img/deepbook-menu-hover.png";
import deepbook_img_sui from "./images/sui-img/deepbook-menu-sui.png";
import deepbook_img from "./images/sui-img/deepbook-menu.png";
import limit_beta from "./images/sui-img/limit-beta.png";
// import mediumHover from "./images/default-img/mediumHover.png"
import { createFromIconfontCN } from "@ant-design/icons";
const suiTheme: WormholeConnectTheme = {
  mode: "dark",
  font: "Comic Sans; sans-serif", // UI 中使用的字体可以是应用程序中可用的自定义字体
  primary: grey[500], //按钮使用的主要颜色
  input: "#232323", //用于输入字段、下拉菜单的颜色
  secondary: grey[500], //用于一些 UI 元素的辅助颜色
  text: "#ffffff", //用于文本的主要颜色
  textSecondary: "#ffffff", // 用于较暗文本的辅助颜色
  error: red[500], // 显示错误的颜色，通常为红色
  success: grey[500], //显示成功消息的颜色
  badge: grey[500], // 连锁徽标使用的背景颜
};
const aptosTheme = {
  mode: "dark",
  font: "Comic Sans; sans-serif", // UI 中使用的字体可以是应用程序中可用的自定义字体
  primary: grey[500], //按钮使用的主要颜色
  input: "#232323", //用于输入字段、下拉菜单的颜色
  secondary: grey[500], //用于一些 UI 元素的辅助颜色
  text: "#ffffff", //用于文本的主要颜色
  textSecondary: "#ffffff", // 用于较暗文本的辅助颜色
  error: red[500], // 显示错误的颜色，通常为红色
  success: grey[500], //显示成功消息的颜色
  badge: grey[500], // 连锁徽标使用的背景颜
};

const configSui: WormholeConnectConfig = {
  network: "Mainnet",
  rpcs: {
    Solana: "https://cetus-solanam-e396.mainnet.rpcpool.com",
    Ethereum:
      "https://rpc.ankr.com/eth/0b347bb4edad4c5dceb34839076592309802432d576321be5645344deb6b8551",
  },
  tokensConfig: {
    PSTAKE: {
      symbol: "PSTAKE",
      icon: "https://etherscan.io/token/images/pstakefinance_32.png?v=2",
      tokenId: {
        chain: "Ethereum",
        address: "0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006",
      },
      decimals: 18,
    },
  },
  wrappedTokens: {
    Sui: {
      PSTAKE: {
        Ethereum:
          "0x61017ce4bd0b75b266af53edd60bbe391ba1a9fbe9a7d8553657c94ef73ee2ab::coin::COIN",
      },
    },
    // PSTAKE: {
    //   Sui: "0x61017ce4bd0b75b266af53edd60bbe391ba1a9fbe9a7d8553657c94ef73ee2ab::coin::COIN",
    // },
  },
  routes: DEFAULT_ROUTES,
  ui: {
    defaultInputs: {
      toChain: "Sui",
    },
  },
};
const configAptos: WormholeConnectConfig = {
  network: "Mainnet",
  routes: DEFAULT_ROUTES,
  rpcs: {
    Solana: "https://cetus-solanam-e396.mainnet.rpcpool.com",
    Ethereum:
      "https://rpc.ankr.com/eth/0b347bb4edad4c5dceb34839076592309802432d576321be5645344deb6b8551",
  },
  ui: {
    defaultInputs: {
      toChain: "Aptos",
    },
  },
};

const IconFont = createFromIconfontCN({
  scriptUrl:
    "https://at.alicdn.com/t/c/font_3580402_e5j2p4o011t.js?spm=a313x.manage_type_myprojects.i1.13.17ae3a81mkW4er&file=font_3580402_e5j2p4o011t.js",
});

function App() {
  const classesChange = () => {
    const routers = window.location.pathname.toString();

    if (routers === "/aptos") {
      return false;
    } else {
      return true;
    }
  };
  const bodyClassChange = () => {
    const routers = window.location.pathname.toString();
    // console.log(window.location)
    // console.log(routers)
    if (routers === "/aptos") {
      return "body-container-bg body-container-bg-aptos";
    } else {
      return "body-container-bg";
    }
  };
  // let switchShow = true
  // const getChangeSwitch = () => {
  //   setTimeout(() => {
  //     let ids:any = document.getElementById("spinId");
  //     if(!ids) return
  //     ids.remove();
  //   }, 3000);
  // }

  // getChangeSwitch()
  const [messageShow, setMessageShow] = useState(false);
  const [earnShow, setEarnShow] = useState(false);
  const [bridgeShow, setBridgeShow] = useState(false);
  const [tradeShow, setTradeShow] = useState(false);
  const handleClick = () => {
    setMessageShow(!messageShow);
    setEarnShow(false);
    setTradeShow(false);
    setBridgeShow(false);
  };
  const toPage = (url: any) => {
    window.location.href = url;
  };
  const list = [
    {
      name: "Docs",
      icon: "docs",
      iconhover: "docsHover",
      link: "https://cetus-1.gitbook.io/cetus-docs/",
    },
    {
      name: "Twitter",
      icon: "twitter",
      iconhover: "twitterHover",
      link: "https://twitter.com/CetusProtocol",
    },
    {
      name: "Discord",
      icon: "discord",
      iconhover: "discordHover",
      link: "https://discord.gg/rQtYGfmcD8",
    },
    // {
    //   name: "TG Group",
    //   icon: 'group',
    //   iconhover: 'groupHover',
    //   link: 'https://t.me/cetuscommunity'
    // },
    {
      name: "TG Channel",
      icon: "tgchannel",
      iconhover: "tgchannelHover",
      link: "https://t.me/cetusprotocol",
    },
    {
      name: "Medium",
      icon: "medium",
      iconhover: "mediumHover",
      link: "https://medium.com/@CetusProtocol",
    },
  ];
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div className={classesChange() ? "sui" : "aptos"}>
      {/* <div id='spinId'>
        <Spin
          spinning={true}  //是否开启加载中
          size={'large'}  //是否开启加载中
          // tip='Loading...'          //loading文本，这里也可以设置为动态的文本
          style={{
            backgroundColor: 'rgba(0,0,0)', //修改Spin原来的背景颜色
            minHeight: '100vh',                     //使其为整个页面高度
            minWidth: '100%',
            position: 'fixed',
            zIndex: '10000',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
        </Spin>
      </div> */}

      <div className="header">
        <div className={classesChange() ? "headerAll sui-header" : "headerAll"}>
          {/* <Button type="primary">Button</Button> */}
          <div className="top-left-header">
            <p
              className="top-logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                minWidth: "132px",
                marginRight: "28px",
              }}
            >
              <a className="left-logo" href="https://app.cetus.zone/swap">
                {" "}
              </a>
              <div className="left-bridge-logo"></div>
              {/* <span className="left-toothpick"></span> */}
            </p>
            <p
              style={{ display: "flex", alignItems: "center" }}
              className="menu"
            >
              {/* <a href="https://app.cetus.zone/swap" target="_self" rel="noreferrer">Swap</a> */}
              <div
                className="more_icon"
                onClick={() => toPage("https://app.cetus.zone/swap")}
              >
                Trade
                <IconFont type="icon-icon_on" className="icon" />
                <div className="earn-list" style={{ paddingBottom: "0px" }}>
                  <a
                    href="https://app.cetus.zone/swap"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon_swap" className="item-icon" />
                      <span>Swap</span>
                    </div>
                    <div className="start-item"></div>
                  </a>
                  <a href="javascript:;">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        toPage("https://app.cetus.zone/limit?chain=sui");
                      }}
                      className="item"
                    >
                      <div className="more_link">
                        <IconFont
                          type="icon-icon_limitorder"
                          className="item-icon"
                        />
                        <span>Limit Order </span>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://app.cetus.zone/dca?chain=sui"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link more_link_beta">
                      <IconFont type="icon-icon_dca" className="item-icon" />
                      <span>DCA</span>
                      <img className="limit_beta" src={limit_beta} alt="" />
                    </div>
                    <div className="start-item"></div>
                  </a>
                  <a
                    href="https://deepbook.cetus.zone"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      {/* <IconFont type="icon-icon_deepbook" className='item-icon' /> */}
                      {classesChange() && (
                        <img
                          className="default-img"
                          src={deepbook_img_sui}
                          alt=""
                        />
                      )}
                      {!classesChange() && (
                        <img
                          className="default-img"
                          src={deepbook_img}
                          alt=""
                        />
                      )}
                      <img
                        className="hover-img"
                        src={deepbook_img_hover}
                        alt=""
                      />
                      <span>DeepBook</span>
                    </div>
                  </a>
                  <div className="bot_icon"></div>
                </div>
              </div>
              <div className="more_icon">
                Earn
                <IconFont type="icon-icon_on" className="icon" />
                <div className="earn-list" style={{ paddingBottom: "0px" }}>
                  <a
                    href="https://app.cetus.zone/pool/list"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont
                        type="icon-icon_liquiditypools"
                        className="item-icon"
                      />
                      <span>Pools</span>
                    </div>
                    <div className="start-item"></div>
                  </a>
                  <a
                    href="https://app.cetus.zone/farms"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon_farms" className="item-icon" />
                      <span>Farms</span>
                    </div>
                  </a>
                  <a
                    href="https://app.cetus.zone/vaults"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon_vaults" className="item-icon" />
                      <span>Vaults</span>
                    </div>
                  </a>
                  <div className="bot_icon"></div>
                </div>
              </div>
              <a
                href="https://app.cetus.zone/xcetus"
                target="_self"
                rel="noreferrer"
              >
                xCETUS
              </a>

              <div className="more_icon">
                Bridge
                <IconFont type="icon-icon_on" className="icon" />
                <div className="earn-list" style={{ paddingBottom: "0px" }}>
                  <a
                    href="https://bridge.sui.io"
                    className="item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon_sui1" className="item-icon" />
                      <span>Sui Bridge</span>
                    </div>
                  </a>
                  <a
                    //  href="https://bridge.sui.io"
                    className="item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont
                        type="icon-icon_wormhole1"
                        className="item-icon item-icon-active"
                      />
                      <span style={{ color: "#fff" }}>Wormhole</span>
                    </div>
                  </a>
                  <div className="bot_icon"></div>
                </div>
              </div>

              <div className="more_icon">
                More
                <IconFont type="icon-icon_on" className="icon" />
                <div className="more-list">
                  {/* <a href="https://www.portalbridge.com/#/transfer" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-buy-crypto" className='item-icon' />
                        <span>Buy Crypto</span>
                      </div>
                  </a> */}
                  <a
                    href="https://launch.cetus.zone/?chain=sui"
                    target="_self"
                    className="item"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont
                        type="icon-icon_launchpad"
                        className="item-icon ido-icon"
                      />
                      <span>Launchpad</span>
                    </div>
                  </a>
                  <a
                    href="https://app.cetus.zone/stats"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon-Stats" className="item-icon" />
                      <span>Stats</span>
                    </div>
                  </a>
                  <a
                    href="https://cetus-1.gitbook.io/cetus-docs"
                    className="item"
                    target="_self"
                    rel="noreferrer"
                  >
                    <div className="more_link">
                      <IconFont type="icon-icon-Docs1" className="item-icon" />
                      <span>Docs</span>
                    </div>
                  </a>
                  <div className="line"></div>
                  <div className="bot_icon">
                    {list.map((item) => (
                      <a
                        style={{ marginBottom: 8 }}
                        key={item.name}
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className={item.name === "Docs" ? "not_show" : ""}
                      >
                        {/* <img
                        style={{ width: 20, height: 20, marginRight: 8 }}
                        src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                        alt="" /> */}
                        <IconFont
                          type={
                            item.icon === "twitter"
                              ? "icon-svg-twitter"
                              : item.icon === "discord"
                              ? "icon-svg-Discord"
                              : item.icon === "group"
                              ? group
                              : item.icon === "tgchannel"
                              ? "icon-svg-tg"
                              : "icon-svg-medium"
                          }
                          className="item-icon"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              {/* <a className='bridge' href="/">Bridge</a>
              <a href="https://app.cetus.zone/stats?source=bridge" target="_self" rel="noreferrer">Stats</a> */}
            </p>
          </div>
          <div className="select">
            <a
              href="https://www.portalbridge.com/#/transfer"
              target="_self"
              rel="noreferrer"
            >
              <img src={portal} alt="" />
              <span>Switch to Portal Bridge</span>
            </a>
            {/* <div className="spot">
              <span></span>
              <span></span>
              <span></span>
              <div className='exhibit'>
                {list.map((item) => (
                  <a
                    style={{ marginBottom: 8 }}
                    key={item.name}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: 20, height: 20, marginRight: 8 }}
                      src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                      alt="" />
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </div> */}
            <div className="h5-select-modal">
              <img src={selectMenu} alt="" />
              <div className="left-modal">
                <div className="logo">
                  <div className="left-logo"></div>
                  {/* <div className="left-toothpick"></div> */}
                </div>
                <div className="menu-list exhibit">
                  {/* <a href="https://app.cetus.zone/swap" target="_self" rel="noreferrer">Swap</a> */}
                  <div
                    className={
                      classesChange()
                        ? "h5_earn_icon sui-h5_earn_icon"
                        : "h5_earn_icon"
                    }
                    style={{ height: tradeShow ? "220px" : "25px" }}
                    onClick={() => {
                      setTradeShow(!tradeShow);
                      setMessageShow(false);
                      setEarnShow(false);
                      setBridgeShow(false);
                    }}
                  >
                    Trade
                    <IconFont
                      type="icon-icon_on"
                      className="icon"
                      style={{
                        transform: tradeShow ? "rotate(180deg)" : "rotate(0)",
                      }}
                    />
                    <div
                      className="h5_earn-list"
                      style={{ display: tradeShow ? "block" : "none" }}
                    >
                      <div className="line"></div>
                      <a
                        href="https://app.cetus.zone/swap"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_swap"
                            className="item-icon"
                          />
                          <span>Swap</span>
                        </div>
                      </a>
                      <a href="javascript:;">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            toPage("https://app.cetus.zone/limit?chain=sui");
                          }}
                          className="item"
                        >
                          <div className="more_link">
                            <IconFont
                              type="icon-icon_limitorder"
                              className="item-icon"
                            />
                            <span>Limit Order </span>
                          </div>
                        </div>
                      </a>
                      <a
                        href="https://app.cetus.zone/dca?chain=sui"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link more_link_beta">
                          <IconFont
                            type="icon-icon_dca"
                            className="item-icon"
                          />
                          <span>DCA</span>
                          <img className="limit_beta" src={limit_beta} alt="" />
                        </div>
                        <div className="start-item"></div>
                      </a>
                      <a
                        href="https://deepbook.cetus.zone"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          {/* <IconFont type="icon-icon_deepbook" className='item-icon' /> */}
                          {classesChange() && (
                            <img
                              className="default-img"
                              src={deepbook_img_sui}
                              alt=""
                            />
                          )}
                          {!classesChange() && (
                            <img
                              className="default-img"
                              src={deepbook_img}
                              alt=""
                            />
                          )}
                          <img
                            className="hover-img"
                            src={deepbook_img_hover}
                            alt=""
                          />
                          <span>DeepBook</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div
                    className={
                      classesChange()
                        ? "h5_earn_icon sui-h5_earn_icon"
                        : "h5_earn_icon"
                    }
                    style={{ height: earnShow ? "170px" : "25px" }}
                    onClick={() => {
                      setEarnShow(!earnShow);
                      setBridgeShow(false);
                      setMessageShow(false);
                      setTradeShow(false);
                    }}
                  >
                    Earn
                    <IconFont
                      type="icon-icon_on"
                      className="icon"
                      style={{
                        transform: earnShow ? "rotate(180deg)" : "rotate(0)",
                      }}
                    />
                    <div
                      className="h5_earn-list"
                      style={{ display: earnShow ? "block" : "none" }}
                    >
                      <div className="line"></div>
                      <a
                        href="https://app.cetus.zone/pool/list"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_liquiditypools"
                            className="item-icon"
                          />
                          <span>Pools</span>
                        </div>
                      </a>
                      <a
                        href="https://app.cetus.zone/farms"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_farms"
                            className="item-icon"
                          />
                          <span>Frams</span>
                        </div>
                      </a>
                      <a
                        href="https://app.cetus.zone/vaults"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_vaults"
                            className="item-icon"
                          />
                          <span>Vaults</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <a
                    href="https://app.cetus.zone/xcetus"
                    target="_self"
                    rel="noreferrer"
                  >
                    xCETUS
                  </a>

                  <div
                    className={
                      classesChange()
                        ? "h5_earn_icon sui-h5_earn_icon"
                        : "h5_earn_icon"
                    }
                    style={{ height: bridgeShow ? "120px" : "25px" }}
                    onClick={() => {
                      setBridgeShow(!bridgeShow);
                      setEarnShow(false);
                      setMessageShow(false);
                      setTradeShow(false);
                    }}
                  >
                    Bridge
                    <IconFont
                      type="icon-icon_on"
                      className="icon"
                      style={{
                        transform: bridgeShow ? "rotate(180deg)" : "rotate(0)",
                      }}
                    />
                    <div
                      className="h5_earn-list"
                      style={{ display: bridgeShow ? "block" : "none" }}
                    >
                      <div className="line"></div>
                      <a
                        href="https://bridge.sui.io"
                        className="item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_sui1"
                            className="item-icon"
                          />
                          <span>Sui Bridge</span>
                        </div>
                      </a>
                      <a
                        // href="https://app.cetus.zone/vaults"
                        className="item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_wormhole1"
                            className="item-icon item-icon-active"
                          />
                          <span style={{ color: "#fff" }}>Wormhole</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="h5_more_icon" onClick={() => handleClick()}>
                    More
                    <IconFont
                      type="icon-icon_on"
                      className="icon"
                      style={{
                        transform: messageShow ? "rotate(180deg)" : "rotate(0)",
                      }}
                    />
                    <div
                      className="h5_more-list"
                      style={{ display: messageShow ? "block" : "none" }}
                    >
                      <div className="line"></div>
                      <a
                        href="https://launch.cetus.zone/?chain=sui"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon_launchpad"
                            className="item-icon"
                          />
                          <span>Launchpad</span>
                        </div>
                      </a>

                      <a
                        href="https://app.cetus.zone/stats"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon-Stats"
                            className="item-icon"
                          />
                          <span>Stats</span>
                        </div>
                      </a>
                      <a
                        href="https://cetus-1.gitbook.io/cetus-docs"
                        className="item"
                        target="_self"
                        rel="noreferrer"
                      >
                        <div className="more_link">
                          <IconFont
                            type="icon-icon-Docs1"
                            className="item-icon"
                          />
                          <span>Docs</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* <a className='bridge' href="/">Bridge</a> */}
                  {/* <a href="https://app.cetus.zone/stats?source=bridge" target="_self" rel="noreferrer">Stats</a> */}
                </div>
                <div className="footer-box select">
                  <a
                    href="https://www.portalbridge.com/#/transfer"
                    target="_self"
                    rel="noreferrer"
                  >
                    <img src={portal} alt="" />
                    <span>Switch to Portal Bridge</span>
                  </a>

                  <div className="exhibit">
                    {list.map((item) => (
                      <a
                        key={item.name}
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className={item.name === "Docs" ? "not_show" : ""}
                      >
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                          alt="" /> */}
                        <IconFont
                          type={
                            item.icon === "twitter"
                              ? "icon-svg-twitter"
                              : item.icon === "discord"
                              ? "icon-svg-Discord"
                              : item.icon === "group"
                              ? group
                              : item.icon === "tgchannel"
                              ? "icon-svg-tg"
                              : "icon-svg-medium"
                          }
                          className="item-icon"
                        />
                        {/* <span>{item.name}</span> */}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WormholeBridge
        config={classesChange() ? configSui : configAptos}
        theme={classesChange() ? suiTheme : aptosTheme}
      />
      <div className={bodyClassChange()}></div>
    </div>
  );
}

export default App;
